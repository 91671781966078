import carouselOne from '/static/images/use-case/by-scenario/ai-generated_content_feature_1.webp'
import carouselTwo from '/static/images/use-case/by-scenario/ai-generated_content_feature_2.webp'
import carouselThree from '/static/images/use-case/by-scenario/ai-generated_content_feature_3.webp'
import carouselFour from '/static/images/use-case/by-scenario/ai-generated_content_feature_4.webp'
import tabOne from '/static/images/use-case/by-scenario/ai-generated_content_scenario_ai-enabled_asset_optimization.webp'
import tabTwo from '/static/images/use-case/by-scenario/ai-generated_content_scenario_lead_in_speed_and_performance.webp'
import tabThree from '/static/images/use-case/by-scenario/ai-generated_content_scenario_comprehensive_data_analysis.webp'
import tabFour from '/static/images/use-case/by-scenario/ai-generated_content_scenario_ai_generated_content_and_ad_inventory.webp'

export const aiTabs = [
  {
    id: 'optimization',
    content: 'AI-enabled asset optimization',
    picture: tabOne,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `We combine delivery and AI technologies to significantly improve content loading speeds, enhancing SEO rankings and user satisfaction.`
  },
  {
    id: 'performance',
    content: 'Lead in speed and performance',
    picture: tabTwo,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `We combine delivery and AI technologies to significantly improve content loading speeds, enhancing SEO rankings and user satisfaction.`
  },
  {
    id: 'analysis',
    content: 'Comprehensive data analysis',
    picture: tabThree,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Offering precise data analysis and optimization reports to support informed business decisions.`
  },
  {
    id: 'inventory',
    content: 'AI generated content and ad inventory',
    picture: tabFour,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Utilizing AI search to generate more content and ad inventory to help you monetize faster.`
  }
]

export const aiFeatures = [
  {
    title: 'Slow content loading',
    content:
      'Slow loading can lead to traffic loss and user churn, as users are less likely to engage with content that takes too long to load.',
    imageSrc: carouselOne,
    imageAlt: 'Slow content loading'
  },
  {
    title: 'Multimedia content management',
    content: 'Content often fails to adapt seamlessly across different devices, network speeds, and usage scenarios.',
    imageSrc: carouselTwo,
    imageAlt: 'Multimedia content management'
  },
  {
    title: 'High operational costs',
    content: 'The optimization and generation of digital assets require significant time and investment.',
    imageSrc: carouselThree,
    imageAlt: 'High operational costs'
  },
  {
    title: 'Monetisation',
    content: `Struggle with low ad inventory utilization rates, making it difficult to increase revenue.`,
    imageSrc: carouselFour,
    imageAlt: 'Monetisation'
  }
]
