import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import Cover from 'components/common/cover'
import WideCard from 'components/common/wideCard'
import UseCase from 'components/common/useCase'
import TabCover from 'components/common/tabCover'
import ContentCarousel from 'components/common/contentCarousel'
import ai from '/static/images/use-case/by-scenario/ai-generated_content_hero.webp'
import aiCard from '/static/images/use-case/by-scenario/ai-generated_content_bar.webp'
import { aiTabs, aiFeatures } from '/static/data/use-case/ai-generated.static'

const AIGeneratedContent = () => {
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: `Use Case: AI-Generated Content - Mlytics`,
            metaDescription: `Mlytics' platform optimizes content delivery and enhances user engagement using AI, increasing revenue through improved advertising strategies.`,
            metaUrl: 'www.mlytics.com/use-case/ai-generated-content',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section
          style={{
            height: 'calc(100vh - 100px)',
            minHeight: '600px'
          }}
          className="px-9 md:px-[86px] mb-5 lg:max-h-[800px] base:absolute base:left-1/2 base:-translate-x-1/2 base:w-screen base:max-w-[1920px]"
        >
          <Cover
            smallTitle="Transform Your Content, Transform Your Business."
            title="Revitalise Your Digital Assets with AI"
            imgSrc={ai}
            imgAlt="AIGC Hero Picture"
            paragraphContent="Mlytics platform’s unrivaled digital asset optimization capabilities, combined with AI capabilities allow you to  optimize content delivery, enhance user engagement, and increase revenue through improved advertising strategies."
            actionLinkOne="chatbot"
            actionTextOne="Learn more"
          />
        </section>
        <div className="hidden mb-5 lg:max-h-[800px] base:block base:h-[calc(100vh-100px)] base:min-h-[600px]"></div>
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <h2 className="h2-block-title text-black-100 text-center">Challenge</h2>
            <ContentCarousel listItem={aiFeatures} />
          </div>
        </section>
        <section className="py-[60px] px-9 md:px-[86px] flex justify-center">
          <TabCover title="Mlytics Way" tabItems={aiTabs} />
        </section>
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <WideCard
              imgSrc={aiCard}
              imgAlt="Deliver Better"
              paragraphTitle="Deliver Better. Optimize Smarter. Monetize Faster."
              paragraphContent=""
              actionLink="/contact-us"
              actionText="Contact us"
              actionColor="btn-cover-orange"
            />
          </div>
        </section>
        <section className="py-[60px]">
          <UseCase />
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default AIGeneratedContent

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
